import React, { useContext, useState } from "react";
import "./lobby.scss";
import GameList from "./component/game-list/GameList";
import { AuthContext } from "../../context/AuthContext";
import GameCat from "./component/game-cat/GameCat";
import Announcement from "./component/announcement/Announcement";
import Footer from "./component/footer/Footer";
import Header from "./component/header/Header";
import AngpaoModal from "./component/angpao-modal/AngpaoModal";
import LogoutModal from "./component/logout-modal/LogoutModal";
import { getPlayerBalance } from "../../services/player-api/PlayerServices"

function Lobby() {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { isVertical } = useContext(AuthContext);
    const [logoutVisible, setLogoutVisible] = useState(false);
    const [angpaoVisible, setAngpaoVisible] = useState(false);

    const openAngpao = async () =>{
      try {
        setAngpaoVisible(true);
        return;
      } catch (err) {
        setError(err.message || "Failed to open Angpao");
      } finally {
        setIsLoading(false);
      }
    }

    if (isVertical) {
        return (
            <div className="v-lobby-container">
                <div className="lb-header-container">
                    <Header setLogoutVisible={setLogoutVisible} />
                </div>
                <div className="lb-announcement-container">
                    <Announcement />
                </div>
                <div className="lb-footer-container">
                    <Footer />
                </div>
                <div className="lb-game-cat-container">
                    <GameCat />
                </div>
                <div className="lb-game-container">
                    <GameList />
                </div>
                <LogoutModal logoutVisible={logoutVisible} setLogoutVisible={setLogoutVisible} />
            </div>
        );
    } else {
        return (
            <div className="h-lobby-container">
                <div className="lb-header-container">
                    <Header setLogoutVisible={setLogoutVisible} />
                </div>
                <div className="lb-main-container">
                    <div className="lb-game-cat-container">
                        <GameCat />
                    </div>
                    <div className="lb-sub-container">
                        <div className="lb-announcement-container">
                            <Announcement />
                        </div>
                        <div className="lb-game-container">
                            <GameList />
                        </div>
                    </div>
                </div>
                <div className="lb-footer-container">
                    <Footer />
                </div>
                <img src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/AngPao-01.png" 
                className="overlay-img" 
                style={{display: angpaoVisible ? "none" : "grid"}} 
                alt="Overlay Image" />
                <div className="click-area" onClick={openAngpao}></div>
                {/*<AngpaoModal angpaoVisible={angpaoVisible} setAngpaoVisible={setAngpaoVisible} />*/}
                <LogoutModal logoutVisible={logoutVisible} setLogoutVisible={setLogoutVisible} />
            </div>
        );
    }
}

export default Lobby;
