import { createContext, useEffect, useState } from "react";
import { validatePlayerToken } from "../services/player-api/PlayerServices";
import { Navigate, useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [isVertical, setIsVertical] = useState(false);
    const [category, setCategory] = useState("slot");
    const [playerData, setPlayerData] = useState({});
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        function handleWindowWidth() {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
            setIsVertical(windowWidth <= windowHeight);
        }
        handleWindowWidth();
        window.addEventListener("resize", handleWindowWidth);

        return () => {
            window.removeEventListener("resize", handleWindowWidth);
        };
    }, [windowWidth]);

    // useEffect(() => {
    //     handleValidateUserToken();
    // }, []);

    async function handleValidateUserToken() {
        const finalUserInput = {};
        try {
            const result = await validatePlayerToken(finalUserInput);
            if (result) {
                setPlayerData(result.data);
                localStorage.setItem("playerID", result.data.playerID);
                localStorage.setItem("token", result.data.token);
                setIsLogin(true);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    if (isLogin) {
        <Navigate to="/lobby" replace />;
    } else {
        <Navigate to="/" replace />;
    }

    const values = { windowWidth, windowHeight, isVertical, category, setCategory, playerData, isLogin, setIsLogin };

    return <AuthContext.Provider value={values}>{props.children}</AuthContext.Provider>;
};
