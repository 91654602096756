import React, { useContext } from "react";
import Ticker from "react-ticker";
import "./announcement.scss";
import { AuthContext } from "../../../../context/AuthContext";

function Announcement() {
    const { isVertical } = useContext(AuthContext);

    return (
        <div className={`${isVertical ? "v" : "h"}-annc-container`}>
            <div className="annc-wrapper">
                <div className="annc-content">
                    <Ticker offset="run-in" speed={10} mode="await">
                        {({ index }) => <p>Announcement</p>}
                    </Ticker>
                </div>
            </div>
            <div className={`menu-btn-container ${isVertical}`}>
                <img className="menu-btn-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/menu.png" alt="" />
            </div>
            <div className="annc-btn-container">
                <img className="annc-btn-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/announcment.png" alt="" />
            </div>
        </div>
    );
}

export default Announcement;
