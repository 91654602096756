import apiBaseUrl from "../ApiBaseUrl";

export async function playerLogin(userInput) {
    const response = await apiBaseUrl().post("/players/login", userInput);
    const data = response.data;

    return data;
}

export async function validatePlayerToken(userInput) {
    const response = await apiBaseUrl().post("/player/validate-token", userInput);
    const data = response.data;

    return data;
}

export async function getPlayerBalance(userInput) {
    // Construct query parameters string
    //const params = new URLSearchParams(userInput).toString();
    // Send GET request with query parameters
    //const response = await apiBaseUrl().get(`/players/balance?${params}`);
    const response = await apiBaseUrl().post("/players/balance", userInput);
    const data = response.data;

    return data;
}

export async function getPlayerAction(userInput) {
    // Construct query parameters string
    //const params = new URLSearchParams(userInput).toString();
    // Send GET request with query parameters
    //const response = await apiBaseUrl().get(`/players/balance?${params}`);
    const response = await apiBaseUrl().post("/players/playerAction", userInput);
    const data = response.data;

    return data;
}