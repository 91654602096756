import React, { useContext } from "react";
import "./gamecat.scss";
import { AuthContext } from "../../../../context/AuthContext";

function GameCat() {
    const { isVertical, category, setCategory } = useContext(AuthContext);

    const gameCat = [
        "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/slot.png",
        "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/live.png",
        "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/recent.png",
    ];

    return (
        <div className={`${isVertical ? "v" : "h"}-game-cat-container`}>
            <div className="cat-title">
                <img className="cat-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/category.png" alt="" />
            </div>
            {gameCat?.map((cat, index) => (
                <div key={index} className="cat" onClick={() => setCategory(cat?.split("/").slice(-1)[0].split(".")[0])}>
                    <img className={`cat-img ${cat?.split("/").slice(-1)[0].split(".")[0] === category}`} src={cat} alt="" />
                </div>
            ))}
        </div>
    );
}

export default GameCat;
