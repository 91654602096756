import React from "react";
import ContentHeader from "../../components/content-header/ContentHeader";
import "./transaction.scss";

function Transaction() {
    return (
        <div className="transaction-container">
            <div className="tc-header-container">
                <ContentHeader />
            </div>
            <div className="tc-main-container">
                <div className="tc-table"></div>
            </div>
        </div>
    );
}

export default Transaction;
