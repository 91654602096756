import React from "react";
import { useNavigate } from "react-router-dom";
import "./contentheader.scss";

function ContentHeader() {
    const navigate = useNavigate();

    return (
        <div className="ct-header-container">
            <div className="ct-header-back" onClick={() => navigate(-1)}>
                Back
            </div>
            <div className="ct-header-title">Suggestion</div>
            <div className="ct-header-empty" />
        </div>
    );
}

export default ContentHeader;
