import React, { useContext } from "react";
import "./mainlayout.scss";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

function MainLayout() {
    const { isVertical } = useContext(AuthContext);
    return (
        <div
            className="layout-container"
            style={{
                backgroundImage: `url(https://game-platform.sgp1.digitaloceanspaces.com/bwg/${isVertical ? "v" : "h"}-bg.png)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}
        >
            <Outlet />
        </div>
    );
}

export default MainLayout;
