import React, { useContext, useState, useEffect } from "react";
import "./gamelist.scss";
import { message } from "antd";
import Cookies from "js-cookie";
import { AuthContext } from "../../../../context/AuthContext";
import { getLobbyGame, launchGame } from "../../../../services/lobby-api/LobbyServices"
import { getPlayerAction } from "../../../../services/player-api/PlayerServices"

function GameList() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isVertical, category } = useContext(AuthContext);

  const [gameList, setGameList] = useState(null);
  const [recentGameList, setRecentGameList] = useState(null);
  const [filteredGameList, setFilteredGameList] = useState(null);

  useEffect(() => {
    if (gameList) {
      if(category == "recent"){
        console.log(recentGameList)
        setFilteredGameList(gameList.filter(game => recentGameList.some(recentGame => recentGame.gameID === game.gameID)));
        console.log(filteredGameList)
      }
      else{
        setFilteredGameList(gameList.filter(game => game.category === category));
      }
    }
  }, [gameList, category]);

  useEffect(() => {
    const fetchLobbyGame = async () => {
      try {
        setIsLoading(true);
        const data = await getLobbyGame();
        setGameList(data);
      } catch (err) {
        setError(err.message || "Failed to fetch data");
      } finally {
        
        setIsLoading(false);
      }
    };
    const fetchRecentGame = async () => {
      try {
        setIsLoading(true);
        const data = await getPlayerAction(
          {
            playerID: Cookies.get("PlayerID")
          }
        );  
        setRecentGameList(data);
      } catch (err) {
        setError(err.message || "Failed to get player action");
      } finally {
        
        setIsLoading(false);
      }
    };

    fetchLobbyGame();
    fetchRecentGame();
    }, []);

    const startGame = async (gp, id) =>{
      try {
        setIsLoading(true);
        var playerID = Cookies.get("PlayerID");
        
        if(playerID !== undefined || playerID != ""){

          const result = await launchGame(
            {
              playerID: Cookies.get("PlayerID"), gameID: id, provider: gp
            }
          );  
          window.location.assign(result.link);
        }  
        
        return;
      } catch (err) {
        setError(err.message || "Failed to launch game");
      } finally {
        setIsLoading(false);
      }
    }

    return (
        <div className={`${isVertical ? "v" : "h"}-game-container`}>
            <div className="game-wrapper">
                {filteredGameList?.map((game, index) => (
                    <div key={index} className="game" onClick={() => startGame(game.provider, game.gameID)}>
                        <img className="game-img" src={game.img} alt="" />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default GameList;
