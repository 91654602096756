import React, { useContext, useState } from "react";
import "./login.scss";
import { Button, Col, Form, Input, Row } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { AuthContext } from "../../context/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { playerLogin } from "../../services/player-api/PlayerServices"

function Login() {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { isLogin, setIsLogin } = useContext(AuthContext);

    async function handleLogin(values) {
        try {
          setIsLoading(true);
          const result = await playerLogin(
            {
              username: values.playerID,
              password: values.password
            }
          );    
          setIsLogin(true);
          Cookies.set("PlayerID", result.playerID);
          Cookies.set("UserName", values.playerID);
          return;
        } catch (err) {
          setError(err.message || "Failed to get Balance");
        } finally {
          setIsLoading(false);
        }
    }

    if (isLogin) {
        navigate("/lobby", { replace: true });
    }

    return (
        <div
            className="login-container"
            style={{
                backgroundImage: `url(https://game-platform.sgp1.digitaloceanspaces.com/bwg/login-bg.png)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}
        >
            <div className="login-logo">
                <img className="login-logo-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/logo.png" alt="" />
            </div>
            <div className="login-wrapper">
                <Form className="login-form" layout="vertical" form={form} onFinish={handleLogin}>
                    <Row justify="center" align="middle">
                        <Col xs={24}>
                            <Form.Item name="playerID">
                                <Input prefix={<UserOutlined />} placeholder="Player ID" size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item name="password">
                                <Input.Password prefix={<LockOutlined />} placeholder="Password" size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item>
                                <Button block htmlType="submit" type="primary" size="large" shape="round">
                                    LOGIN
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default Login;
