import React, { useContext, useState, useEffect } from "react";
import "./footer.scss";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { AuthContext } from "../../../../context/AuthContext";

function Footer() {
    const navigate = useNavigate();
    const { isVertical } = useContext(AuthContext);
    // Initialize the jackpot value from cookies or with a random number if the cookie doesn't exist
    const [jackpot, setJackpot] = useState(() => {
      const savedJackpot = Cookies.get('bwg-jackpot');
      return savedJackpot ? parseInt(savedJackpot, 10) : Math.floor(Math.random() * 50000) + 50000;
    });
  
    useEffect(() => {
      // Define an interval to update the jackpot state every second
      const intervalId = setInterval(() => {
        const randomAddition = Math.floor(Math.random() * 500); // Random number to add or subtract
        const randomDeduction = Math.floor(Math.random() * 5000); // Random number to add or subtract
        
        // Introduce a small chance (1 in 1000) to deduct instead of adding
        const shouldDeduct = Math.random() < 0.01; // 0.1% chance of deduction
  
        setJackpot(prevJackpot => {
          const newJackpot = shouldDeduct 
            ? Math.max(0, prevJackpot - randomDeduction) // Deduct the amount, ensuring jackpot doesn't go below 0
            : prevJackpot + randomAddition; // Add the amount
  
          // Update the cookie with the new jackpot value
          Cookies.set('jackpot', newJackpot, { expires: 7 }); // Cookie expires in 7 days
          return newJackpot;
        });
      }, 1000); // Update every 1000ms (1 second)
  
      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="footer-container">
            <div className={`ft-suggestion-container ${isVertical}`} onClick={() => navigate("/suggestion")}>
                <img className="ft-suggestion-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/suggestion.png" alt="" />
            </div>
            <div className="ft-jackpot-container">
                <img className="ft-jackpot-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/jackpot.png" alt="" />
                <p className="ft-jackpot-text">{jackpot}</p>
            </div>
            <div className={`ft-transaction-container ${isVertical}`} onClick={() => navigate("/transaction")}>
                <img className="ft-transaction-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/transaction.png" alt="" />
            </div>
        </div>
    );
}

export default Footer;
