import React from "react";
import "./suggestion.scss";
import Header from "../lobby/component/header/Header";
import { useNavigate } from "react-router-dom";
import ContentHeader from "../../components/content-header/ContentHeader";

function Suggestion() {
    const navigate = useNavigate();

    return (
        <div className="suggestion-container">
            <div className="sg-header-container">
                <ContentHeader />
            </div>
            <div className="sg-main-container">
                <div className="sg-type">
                    <div className="sg-title">
                        <p>Suggestion Type:</p>
                    </div>

                    <div className="sg-box"></div>
                </div>
                <div className="sg-message">
                    <div className="sg-title">
                        <p>Message:</p>
                    </div>
                    <div className="sg-box"></div>
                </div>
            </div>
        </div>
    );
}

export default Suggestion;
